const SET_PAUSED_CONSULTATION =
  "playbook/paisedConsultations/SET_PAUSED_CONSULTATION";

const initialState = {
  consultations: [],
  fetchDate: null,
};

const pausedConsultations = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAUSED_CONSULTATION: {
      return {
        ...state,
        consultations: action.consultations,
        fetchDate: new Date(),
      };
    }
    default:
      return state;
  }
};

//actions
export const setPausedConsultations = (consultations) => ({
  type: SET_PAUSED_CONSULTATION,
  consultations,
});

//selector
export const getPausedConsultations = (state) => state.pausedConsultations;
export default pausedConsultations;
