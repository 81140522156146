import { h, Fragment } from "preact";
import { useState, useEffect } from "preact/hooks";
import { forwardRef } from "preact/compat";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CloseIconCircle from "../SvgIcons/CloseIconCircle";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import PauseIcon from "@material-ui/icons/Pause";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import StopIcon from "@material-ui/icons/Stop";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import {
  Box,
  Dialog,
  Slide,
  Tab,
  Tabs,
  Badge,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getIsADM } from "../../redux/modules/settingsReducer";
import { isLoggedIn } from "../../redux/modules/userReducer";
import { getUnreadAmount } from "../../redux/modules/newsReducer";

import ConsultationsPage from "./ADMProfile/ConsultationsPage";
import ADMProfile from "./ADMProfile/ADMProfile";
import NewsPage from "./ADMProfile/NewsPage";
import SetupPage from "./Setup/SetupPage";
import ConsultingPage from "./Categories";
import ResultPage from "./Result/ResultPage";

import {
  getConsultingEnd,
  getConsultingStarted,
} from "../../redux/modules/consultingReducer";
import {
  getConsultationStoreAmount,
  setOnlinePausedConsultation,
  setOfflineConsultation,
} from "../../redux/modules/consultationStore";
import { resetConsulting } from "../../redux/modules/reducers";
import ButtonStack from "../ButtonStack";
import { getSetupData } from "../../redux/modules/setupReducer";
import { Detector } from "react-detect-offline"
import { toast } from "react-toastify";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dialog-tab-${index}`}
      aria-labelledby={`dialog-tab-${index}`}
      style={{ height: "100%" }}
      {...other}
    >
      {value === index && <Box height="100%">{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `dialog-tab-${index}`,
    "aria-controls": `dialog-tab-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  fab: {
    "& > *": {
      margin: theme.spacing(1),
    },
    position: "fixed",
    bottom: "25px",
    right: "25px",
    zIndex: "20",
  },
  container: {
    overflow: "scroll",
    overflowScrolling: "touch",
    WebkitOverflowScrolling: "touch",
  },
  alignLeft: {
    marginLeft: "auto",
  },
  tabButtons: {
    minWidth: "0",
    color: "black",
    fontFamily: "Averta-Bold",
    borderBottom: "2px solid #eaeaea",
  },
  dialogPaper: {},
  tabPadding: {
    padding: "1rem",
  },
  appBar: {
    backgroundColor: "var(--grey-20)",
  },
  badge: {
    backgroundColor: "#007a33",
  },
}));

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function BeratungPageDialog({ onConfirm, onCancel, ...props }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [consultingState, setConsultingState] = useState(false);
  const [showConsultingPage, setShowConsultingPage] = useState(false);
  const [showConsultingFinish, setShowConsultingFinish] = useState(false);
  const customerData = useSelector((state) => getSetupData(state));
  const [tabIndex, setTabIndex] = useState("consultations");

  const isADM = useSelector((state) => getIsADM(state));
  const loggedIn = useSelector((state) => isLoggedIn(state));
  const unreadAmount = useSelector((state) => getUnreadAmount(state));
  const consultingStarted = useSelector((state) => getConsultingStarted(state));
  const consultingEnd = useSelector((state) => getConsultingEnd(state));
  const storedConsultationsAmount = useSelector((state) =>
    getConsultationStoreAmount(state)
  );

  const toggleShowConsultingPage = () => {
    setShowConsultingPage((v) => !v);
  };

  const finishConsulting = () => {
    setShowConsultingFinish((v) => !v);
  };

  const handleChange = (event, newValue) => {
    if (newValue !== -1) {
      setTabIndex(newValue);
    }
  };

  const setTab = (value) => {
    setTabIndex(value);
  };

  const pauseConsulting = (online) => {
    if (online) {
      dispatch(setOnlinePausedConsultation(online));
    } else {
      toast("Sie sind offline. Beratung kann nicht auf den Server geladen werden.")
      dispatch(setOfflineConsultation(online));
    }
    dispatch(resetConsulting());
    setTab("consultations");
  };

  useEffect(() => {
    if (open) {
      if (consultingStarted !== null) {
        setTabIndex("consulting");
      } else {
        setTabIndex("consultations");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isADM) {
    return <></>;
  }
  
  const inConsulting = consultingStarted !== null && (consultingEnd === null || consultingEnd === '0001-01-01T00:00:00Z');

  const startNewConsulting = () => {
    setTabIndex("setup");
  };
  const switchConsultingState = (value) => {
    setConsultingState(value);
  };

  return (
    <Dialog
      fullWidth={true}
      scroll="paper"
      maxWidth="xl"
      {...props}
      PaperProps={{
        style: {
          borderRadius: 0,
          height: "calc(100% - 8rem)",
          padding: "2rem",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle style={{ padding: 0 }}>
        {!showConsultingFinish && (
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="standard"
          >
            {inConsulting && (
              <Tab
                className={classes.tabButtons}
                {...a11yProps("consulting")}
                value="consulting"
                label={
                  <>
                    <QuestionAnswerOutlinedIcon color="primary" />
                    &ensp;Gespräch
                  </>
                }
              />
            )}
            {!inConsulting && (
              <Tab
                className={classes.tabButtons}
                {...a11yProps("consultations")}
                value="consultations"
                label={
                  <>
                    <SupervisedUserCircleIcon color="primary" />
                    &ensp;Beratungen
                  </>
                }
              />
            )}
            <Tab
              className={classes.tabButtons}
              {...a11yProps("news")}
              value="news"
              label={
                <>
                  <Badge badgeContent={unreadAmount} color="error">
                    <MailOutlineIcon color="primary" />
                  </Badge>
                  &ensp;News
                </>
              }
            />
            <Tab
              className={classes.tabButtons}
              {...a11yProps("profile")}
              value="profile"
              label={
                <>
                  <AccountCircleIcon color="primary" />
                  &ensp;Profil
                </>
              }
            />
          </Tabs>
        )}

        <Box
          position="absolute"
          top="1.5rem"
          right="1.5rem"
          zIndex={10}
          style={{ cursor: "pointer" }}
        >
          <CloseIconCircle onClick={onCancel} fontSize="large" />
        </Box>
      </DialogTitle>
      <DialogContent
        id="consultationDialog"
        style={{
          padding: 0,
          width: "100%",
          overflowX: "hidden",
          maxHeight: `calc(100% - ${showConsultingFinish ? "0px" : "48px"})`,
          overflowY: "auto",
        }}
      >
        <Fragment>
          <TabPanel value={tabIndex} index="news" dir={theme.direction}>
            <NewsPage />
          </TabPanel>
          <TabPanel value={tabIndex} index="profile" dir={theme.direction}>
            <ADMProfile handleDialogClose={onCancel} />
          </TabPanel>
          <TabPanel
            value={tabIndex}
            index="setup"
            dir={theme.direction}
          ></TabPanel>
          <TabPanel value={tabIndex} index="consulting" dir={theme.direction}>
            {showConsultingFinish ? (
              <ResultPage handleDialogClose={onCancel} customerData={customerData} />
            ) : (
              <>
                {inConsulting &&
                  (showConsultingPage ? (
                    <ButtonStack>
                      <Button
                        onClick={toggleShowConsultingPage}
                        variant="outlined"
                      >
                        Zurück
                      </Button>
                    </ButtonStack>
                  ) : (
                    <ButtonStack>
                      <Button
                        onClick={toggleShowConsultingPage}
                        variant="outlined"
                      >
                        Beratung mit{" "}
                        {customerData.salutation === "Herr" ? "Herrn" : "Frau"}{" "}
                        {customerData.lastname}
                      </Button>
                      <Detector
                        render={({ online }) => {
                          const handlePause = () => {
                            pauseConsulting(online)
                          }
                          
                          return (
                            <Button
                              startIcon={<PauseIcon />}
                              onClick={handlePause}
                              variant="outlined"
                            >
                              Pause
                            </Button>
                          )
                        }}
                      />
                      <Button
                        startIcon={<StopIcon />}
                        onClick={finishConsulting}
                        variant="outlined"
                      >
                        Beenden
                      </Button>
                    </ButtonStack>
                  ))}
                <ConsultingPage show={showConsultingPage} />
              </>
            )}
          </TabPanel>
          <TabPanel
            value={tabIndex}
            index="consultations"
            dir={theme.direction}
          >
            {consultingState ? (
              <SetupPage
                handleDialogClose={onCancel}
                switchConsultingState={switchConsultingState}
              />
            ) : (
              <ConsultationsPage
                handleDialogClose={onCancel}
                switchConsultingState={switchConsultingState}
              />
            )}
          </TabPanel>
        </Fragment>
      </DialogContent>
    </Dialog>
  );
}
