import {
  v5 as uuidv5,
  v4 as uuidv4,
  version as uuidVersion,
  validate as uuidValidate,
} from "uuid";
import { omit } from "ramda";
import APIService from "../../api";
import { setPausedConsultations } from "./pausedConsultations";

const uuidValidateV4 = (uuid) => uuidValidate(uuid) && uuidVersion(uuid) === 4;

const MAX_CONSULTATIONS_OFFLINE = 20;
const SET_CONSULTATION = "playbook/consultationStore/SET_CONSULTATION";
const DELETE_CONSULTATION = "playbook/consultationStore/DELETE_CONSULTATION";

export const initialState = {
  consultations: [],
};

const consultationStore = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONSULTATION: {
      if (state.consultations.length <= MAX_CONSULTATIONS_OFFLINE) {
        return {
          ...state,
          consultations: [...state.consultations, action.data],
        };
      }
      return state;
    }
    case DELETE_CONSULTATION: {
      const consultations = state.consultations.filter(
        (c) => c.consulting.internalID !== action.id
      );
      return {
        ...state,
        consultations,
      };
    }
    default:
      return state;
  }
};

const getPausedConsultationData = (state) => {
  const {
    consulting,
    setup,
    freeathome,
    missiontozero,
    welcome,
    welcomeip,
    knx,
    comfortline,
    installationsgeraete,
    beruehrungslosesschalten,
    jahresgespraech2021,
    jahresgespraech2022,
    cwa,
    serviceAndTools,
    buschflex,
    uk600ak600,
    abbSmarterMobility,
    lbInnovations,
    energieMonitoring,
    jahresgespraech2023,
    moderneElektroinstallation,
    usv,
    jahresgespraech2024,
    flexline,
    neuheiten2024,
  } = state;

  let c = consulting;

  if (!uuidValidateV4(c.internalID)) {
    c.internalID = uuidv5("offline-consultations", uuidv4());
  }

  return {
    consulting: c,
    setup,
    freeathome,
    missiontozero,
    welcome,
    welcomeip,
    knx,
    comfortline,
    installationsgeraete,
    beruehrungslosesschalten,
    jahresgespraech2021,
    jahresgespraech2022,
    cwa,
    serviceAndTools,
    buschflex,
    uk600ak600,
    abbSmarterMobility,
    lbInnovations,
    energieMonitoring,
    jahresgespraech2023,
    moderneElektroinstallation,
    usv,
    jahresgespraech2024,
    flexline,
    neuheiten2024,
  };
};

//actions
export const setOnlinePausedConsultation = () => (dispatch, getState) => {
  let data = getPausedConsultationData(getState());
  const { settings } = getState();
  data.settings = omit(["region"], settings);

  APIService.post(
    "api/paused/consultation",
    JSON.stringify(data),
    "text/plain",
    (status, data) => {
      APIService.get("api/paused/consultation/adm", (status, data) => {
        dispatch(setPausedConsultations(data));
      });
    }
  );
};

//actions
export const setOfflineConsultation = () => (dispatch, getState) => {
  const data = getPausedConsultationData(getState());

  dispatch({
    type: SET_CONSULTATION,
    data,
  });
};

export const deletePausedConsultation = (id) => ({
  type: DELETE_CONSULTATION,
  id,
});

export const getConsultationStore = (state) => state.consultationStore;
export const getConsultationStoreAmount = (state) =>
  state.consultationStore.consultations.length;

export default consultationStore;
