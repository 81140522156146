import { useState, useEffect, useCallback } from "preact/hooks";
import React, { forwardRef } from "preact/compat";
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  FormControlLabel,
  Switch,
  FormControl,
  Select,
  MenuItem,
  styled,
  DialogContent,
  TextField,
  Tooltip,
  ClickAwayListener,
  Fade,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Collapse from "@material-ui/core/Collapse";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesData } from "../../redux/modules/categoriesReducer";
import { fetchUserLogout, isLoggedIn, getFirstname } from "../../redux/modules/userReducer";
import { chapters, typeOfChapters } from "../../static/data";
import {
  addCategory,
  getCustomCategories,
  removeCategory,
} from "../../redux/modules/settingsReducer";
import { route } from "preact-router";
import { useModal } from "mui-modal-provider";
import BeratungPageDialog from "../Beratung/BeratungPageDialog";
import ConsultingIcon from "../SvgIcons/Consulting";
import MenuIcon from "../SvgIcons/Menu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {
  getActiveChapter,
  setActiveChapter,
} from "../../redux/modules/consultingReducer";
import ChapterCard from "../ChapterCard";
import QDialogTitle from "../../components/QDialogTitle";
import { setAllowScrolling } from "../../static/fullpageHelpers";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import FeedbackIcon from "../SvgIcons/FeedbackIcon";
import CloseIconCircle from "../SvgIcons/CloseIconCircle";
import InfoCircle from "../SvgIcons/InfoCircle";
import APIService from "../../api/index";
import { toast } from "react-toastify";
import { Field as HouseField, Form as HouseForm } from "houseform";
import { z } from "zod";
import { NumericFormat } from "react-number-format";
import { none } from "ramda";
import SaveIcon from "../SvgIcons/SaveIcon";
import { useLocalFilterStorage } from "../../hooks/useLocalFilterStorage";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    padding: "40px",
    zIndex: "2",
    overflow: "hidden",
  },
  consultationDialogIcon: {
    right: "10px",
    bottom: "25px",
    zIndex: "20",
    position: "fixed",
  },
  feedbackDialogIcon: {
    right: "60px",
    bottom: "25px",
    zIndex: "20",
    position: "fixed",
  },
  headline: {
    fontFamily: "Averta-SemiBold",
    cursor: "pointer",
  },
  closeButton: {
    marginLeft: "1em",
    color: "#000",
  },
  loginButton: {
    border: "1px solid black",
  },
  chapterPaper: {
    padding: "1.8rem 1.4rem",
    fontSize: "1.2rem",
    minHeight: "1.2rem",
    margin: "0.5rem",
    fontWeight: 400,
    textDecoration: "none",
    transition: "all .2s ease-in",
    cursor: "pointer",
    "&:hover, &:focus": {
      textDecoration: "none",
      backgroundColor: "var(--grey-10)",
    },
  },
  chapterActive: {
    backgroundColor: "#000",
    color: "#fff",
    "&:hover, &:focus": {
      backgroundColor: "#000",
    },
  },
  background: {
    position: "absolute",
    zIndex: 0,
    height: "80%",
    width: "70%",
    background: "var(--grey-20)",
    right: "0",
    top: "0",
  },
  categoryController: {
    top: "28px",
    pointerEvents: "none",
    position: "fixed",
    "& div": {
      pointerEvents: "all",
    },
  },
  appWrap: {
    height: "auto",
  },
  appText: {
    textAlign: "center",
    alignItems: "center",
    fontSize: "clamp(13px, 1.2vw, 1.5rem)",
    fontFamily: "'Averta-Regular'",
  },
  appImg: {
    overflow: "hidden",
    margin: "0 auto",
    "& > img": {
      width: "auto",
      height: "calc(100vh / 9.5)",
    },
  },
  card: {
    height: "100%",
  },
  pointerCursor: {
    cursor: "pointer",
  },
  categoriesFont: {
    fontSize: "1rem",
  },
  chapterTogglePill: {
    borderRadius: "100px",
  },
  selectEmpty: {
    height: "100%",
    "& .MuiSelect-root": {
      height: "100%",
      display: "flex",
      alignItems: "center",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.09)",
      padding: "0px 0px 0px 1em",
    },
  },
  loginInfo: {
    marginRight: theme.spacing(2)
  }
}));

const MuiIconButton = styled(IconButton)({
  right: "10px",
  top: "10px",
  zIndex: "20",
  position: "fixed",
  "&:hover": {
    backgroundColor: "transparent",
  },
});

const MuiToggleButton = styled(ToggleButton)({
  backgroundColor: "var(--grey-20)",
  borderRadius: "100px",
  border: "1px solid var(--grey-20)",
  color: "rgba(0, 0, 0, 0.87) !important",
  boxShadow:
    "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
  padding: "6px 16px",
  fontSize: "0.875rem",
  transition: "box-shadow 250ms ease-in-out, border 250ms ease-in-out",
  "&.Mui-selected": {
    backgroundColor: "rgba(0, 0, 0, 0.05) !important",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    color: "rgba(0, 0, 0, 0.87) !important",
    boxShadow: "none",
    transition: "box-shadow 250ms ease-in-out, border 250ms ease-in-out",
  },
  "& .MuiToggleButton-label": {
    display: "flex",
    alignItems: "center",
  },
  "& span": {
    pointerEvents: "none",
  },
});

const MuiSelect = styled(Select)({
  "&.selected": {
    borderBottom: "0px",
  },
  "&:before": {
    borderBottom: "0px",
  },
});

const Transition = forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

export default function Menu({setIsLoading}) {

function moveToFirst(array, key, value) {
  const index = array.findIndex(item => item[key] === value);
  if (index !== -1) {
    array.unshift(array.splice(index, 1)[0]);
  }
}

  const classes = useStyles();
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const [open, setOpen] = useState(false);
  const loggedIn = useSelector((state) => isLoggedIn(state));
  const firstName = useSelector(getFirstname)
  const categoriesData = useSelector((state) => getCategoriesData(state));
  const activeChapterId = useSelector((state) => getActiveChapter(state));
  const categories = categoriesData.categories;
  const customCategories = useSelector((state) => getCustomCategories(state));
  const [collapseState, setCollapseState] = useState({});
  const [appFilter, setAppFilter] = useState(() => []);
  const [activeTab, setActiveTab] = useState(true);
  const [apperence, setApperence] = useState(true);
  const [toSave, setToSave] = useState(false);

  const [chapterCards, setChapterCards] = useState([]);
  const [applicationData, setApplicationData] = useState([]);
  const [applicationToggleData, setApplicationToggleData] = useState([]);
  const [openTooltip1, setOpenTooltip1] = useState(false);
  const [openTooltip2, setOpenTooltip2] = useState(false);
  const [feedbackDialog, setFeedbackDialog] = useState(false);
  const [toggleFilter, setToggleFilter] = useState(false, {});
  const [filterData, setFilterData] = useLocalFilterStorage();
  const [orderBy, setOrderBy] = React.useState(filterData);
  
  const [chapterDataAsArray, setChapterDataAsArray] = React.useState([]);
  const [chapterDataAsArrayBackup, setChapterDataAsArrayBackup] =
    React.useState([]);

  useEffect(() => {
    let cState = {};
    Object.keys(typeOfChapters).forEach((type) => {
      cState = { ...cState, [type]: true };
    });

    setCollapseState((c) => ({ ...c, ...cState }));
    setIsLoading(false)
  }, []);

  const handleClickOpen = () => {
    handleOrderBy();
    setOpen(true);
    setAllowScrolling(false);
  };

  const handleClose = () => {
    setOpen(false);
    setAllowScrolling(true);
  };

  const toggleFeedbackDialog = () => {
    setFeedbackDialog(!feedbackDialog);
  };

  const openConsultationDialog = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAllowScrolling(false);
    const dialog = showModal(BeratungPageDialog, {
      onConfirm: () => {
        dialog.hide();
        setAllowScrolling(true);
      },
      onCancel: () => {
        dialog.hide();
        setAllowScrolling(true);
      },
    });
  };

  const logout = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(fetchUserLogout());
    handleClose();
  };

  const routeToChapter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveChapter(chapters[e.target.attributes.alt.value].chapterID);
    route(chapters[e.target.attributes.alt.value].link, true);
    handleClose();
  }, []);

  const routeToIndex = useCallback((e) => {
    route("/", true);
    handleClose();
  });

  const handleTabChange = () => {
    setActiveTab((activeTab) => !activeTab);
  };

  const handleCustomCategories = useCallback(
    (e, value) => {
      e.preventDefault();
      e.stopPropagation();
      if (customCategories.includes(value)) {
        dispatch(removeCategory(value));
      } else {
        dispatch(addCategory(value));
      }
    },
    [customCategories, dispatch]
  );

  const handleAddFeedback = (values) => {
    APIService.post(
      "api/feedback/add",
      values,
      "application/json",
      (status, data) => {
        if (status === 200) {
          toast.info("Feedback gespeichert.");
        }
      }
    );
  };

  const NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="$"
      />
    );
  };

  useEffect(() => {
    /*
      EINMALIG AUFRUFEN:
      In der data.js sind die Kapitel als ein Objekt und nicht Sortierfähig,
      weshalb wir diese in ein Array umwandeln müssen
    */
    const convertedChapterData = [];
    // Gehe die gesamte Liste in data.js durch
    Object.keys(chapters).forEach((key) => {
      if (categories[key]) {
        const chapterObject = {
          name: key,
          data: chapters[key],
        };
          convertedChapterData.push(chapterObject);
      }
    });

    moveToFirst(convertedChapterData, "name", "newsUndAktionen")

    setChapterDataAsArray(convertedChapterData);
    setChapterDataAsArrayBackup(convertedChapterData);
  }, [categories, customCategories]);

  // Kapitel
  useEffect(() => {
    // Kapitel generieren
    const genChapterData = chapterDataAsArray.map((chapter) => {
      if (chapter !== undefined) {
        const activeChapter = chapter.data.chapterID === activeChapterId;
        return (
          <ChapterCard
            chapter={chapter.data}
            active={activeChapter}
            regularFont={true}
            cardWidth="auto"
            favorite={customCategories.indexOf(chapter.data.slug) !== -1}
            onIconClick={handleCustomCategories}
            onCardClick={routeToChapter}
          />
        );
      }
    });
    setChapterCards(genChapterData);
  }, [
    chapterDataAsArray,
    orderBy,
    customCategories,
    activeChapterId,
    routeToChapter,
    handleCustomCategories,
  ]);

  // Anwendungen
  useEffect(() => {
    let genApplicationToggleData = [];
    let genApplicationData = [];
    Object.keys(typeOfChapters["applications"].chapters).map((i) => {
      const currentAppData = typeOfChapters["applications"].chapters[i];
      const currentChapterData = chapters[currentAppData.id];
      // Toggle Buttons für Anwendungen
      genApplicationToggleData.push(
        <MuiToggleButton
          onChange={handleAppFilterAdd}
          value={currentChapterData.slug}
        >
          {currentChapterData.shortLabelObject || currentChapterData.label}
        </MuiToggleButton>
      );
      // Einzelne Anwendungen
      {
        appFilter.indexOf(currentAppData.id) !== -1 || appFilter.length === 0
          ? currentAppData.data.map((app) => {
              if (!app.exclude)
                genApplicationData.push(
                  <ChapterCard chapter={app} active={false} cardWidth="auto" />
                );
            })
          : null;
      }
    });

    setApplicationToggleData(genApplicationToggleData);
    setApplicationData(genApplicationData);
  }, [appFilter]);

  const handleAppFilterAdd = (e, newFilter) => {
    setAppFilter(newFilter);
  };

  const handleOrderBy = (event) => {
    // newOrderBy wird auf Basis der Änderungen angepasst
    const newOrderBy =
      event === undefined
        ? orderBy
        : {
            ...orderBy,
            [event.target.name]:
              event.target.name === "onlyFavorite"
                ? !orderBy.onlyFavorite
                : event.target.value,
          };

    // Array in welchem die Daten stück für stück eingefügt werden um anschl. den State zu überschreiben
    let newConvertedChapterData = [];

    // Welcher Filter wurde gewählt? Datum oder Alphabetische sortierung?
    switch (newOrderBy.field) {
      case "date":
        // Gehe jedes Kapitel durch und prüfe:
        // Ist dieses Kapitel ein Favorit, insofern relevant?
        // Gehört das Kapitel der jeweiligen Marke an, insofern relevant?
        chapterDataAsArrayBackup.forEach((key) => {
          if (
            (newOrderBy.onlyFavorite &&
              customCategories.indexOf(key.data.slug) !== -1) ||
            !newOrderBy.onlyFavorite
          )
            if (
              key.data.company.indexOf(newOrderBy.company) !== -1 ||
              newOrderBy.company === "*"
            ) {
              newConvertedChapterData.push(key);
            }
        });
        // Reihenfolge bestimmen. Wenn falsch, einfach das Array "reversen"
        if (newOrderBy.sort === "desc") newConvertedChapterData.reverse();
        break;
      case "alphabet":
        // Gehe jedes Kapitel durch und prüfe:
        // Ist dieses Kapitel ein Favorit, insofern relevant?
        const temp1 = !newOrderBy.onlyFavorite
          ? chapterDataAsArrayBackup
          : chapterDataAsArrayBackup.filter((key) => {
              return (
                newOrderBy.onlyFavorite &&
                customCategories.indexOf(key.data.slug) !== -1
              );
            });
        // Gehört das Kapitel der jeweiligen Marke an, insofern relevant?
        const temp2 = temp1.map((key) => {
          if (
            key.data.company.indexOf(newOrderBy.company) !== -1 ||
            newOrderBy.company === "*"
          ) {
            return key;
          }
        });

        // Sortiere das neu gefilterte Array Alphabetisch
        const temp3 = temp2.sort((a, b) => {
          const aName = a.data.shortLabel || a.data.label;
          const bName = b.data.shortLabel || b.data.label;
          if (aName < bName) {
            return -1;
          }
          if (aName > bName) {
            return 1;
          }
          return 0;
        });
        // Reihenfolge bestimmen. Wenn falsch, einfach das Array "reversen"
        if (newOrderBy.sort === "desc") temp2.reverse();
        newConvertedChapterData = temp2;
        break;
    }
    // aktualisiere die State's
    setChapterDataAsArray(newConvertedChapterData);
    setOrderBy(newOrderBy);
  };

  const handleToggleFilter = () => {
    setToggleFilter((prev) => !prev);
  };

  const handleOpenTooltip1 = () => {
    setOpenTooltip1(true);
  };

  const handleOpenTooltip2 = () => {
    setOpenTooltip2(true);
  };

  const handleCloseTooltip1 = () => {
    setOpenTooltip1(false);
  };

  const handleCloseTooltip2 = () => {
    setOpenTooltip2(false);
  };

  const saveFilter = () => {
    setApperence(false);

    setTimeout(() => {
      setFilterData(orderBy);
    }, 1500);

    setTimeout(() => {
      setApperence(true);
    }, 1700);
  };

  React.useEffect(() => {
    setToSave(orderBy !== filterData);
  }, [orderBy, filterData]);

  return (
    <div>
      {loggedIn && (
        <>
          <Button
            disableRipple
            color="primary"
            onClick={toggleFeedbackDialog}
            className={classes.feedbackDialogIcon}
          >
            <FeedbackIcon fontSize="large" stroke="black" stroke-width={1} />
          </Button>
          <Button
            disableRipple
            color="primary"
            onClick={openConsultationDialog}
            className={classes.consultationDialogIcon}
          >
            <ConsultingIcon fontSize="large" stroke="black" stroke-width={1} />
          </Button>
        </>
      )}
      <MuiIconButton onClick={handleClickOpen}>
        <MenuIcon fontSize="large" />
      </MuiIconButton>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={feedbackDialog}
        PaperProps={{
          style: {
            borderRadius: 0,
            height: "calc(100% - 8rem)",
            padding: "2rem",
          },
        }}
      >
        <DialogContent
          style={{
            padding: 0,
            width: "100%",
            overflowX: "hidden",
            maxHeight: "calc(100% - 48px)",
            overflowY: "auto",
          }}
        >
          <Box width="70%">
            <QDialogTitle
              disablePaddingTop
              title="Fehlendes Produkt?"
              subTitle="Hier Sortimentslücke melden."
            />
            <HouseForm
              onSubmit={(values, { reset }) => {
                handleAddFeedback({
                  ...values,
                  value: parseFloat(values.value) ? values.value : 0,
                });
                reset();
                setFeedbackDialog(false);
              }}
            >
              {({ isValid, submit }) => (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box
                      marginBottom={1}
                      display="flex"
                      alignItems="center"
                      flexDirection="row"
                    >
                      <Typography variant="body1">Sortimentslücke *</Typography>
                      <ClickAwayListener onClickAway={handleCloseTooltip1}>
                        <div>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            placement="right"
                            onClose={handleCloseTooltip1}
                            open={openTooltip1}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <Typography>
                                In dieses Feld bitte das Produkt notieren,
                                welches derzeit nicht lieferbar ist oder im
                                Produktsortiment fehlt.
                              </Typography>
                            }
                            style={{ padding: 8 }}
                          >
                            <IconButton
                              onClick={handleOpenTooltip1}
                              aria-label="delete"
                              disableRipple
                            >
                              <InfoCircle />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                    </Box>
                    <HouseField
                      name="description"
                      onBlurValidate={z
                        .string()
                        .nonempty("Bitte geben Sie das fehlende Produkt ein.")}
                    >
                      {({ value, setValue, onBlur, errors }) => {
                        return (
                          <TextField
                            fullWidth
                            error={errors.length > 0}
                            helperText={errors.length > 0 ? errors[0] : ""}
                            placeholder="Hier fehlendes Produkt eingeben ..."
                            hiddenLabel={true}
                            type="text"
                            variant="filled"
                            name="description"
                            onBlur={onBlur}
                            onChange={(e) => setValue(e.target.value)}
                            value={value}
                            InputProps={{
                              disableUnderline: true,
                              style: {
                                borderRadius: 0,
                                fontFamily: "Averta-Bold",
                              },
                            }}
                          />
                        );
                      }}
                    </HouseField>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      marginBottom={1}
                      display="flex"
                      alignItems="center"
                      flexDirection="row"
                    >
                      <Typography variant="body1">
                        Schätzung entgangener Umsatz oder Stückzahl
                      </Typography>
                      <ClickAwayListener onClickAway={handleCloseTooltip2}>
                        <div>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            placement="right"
                            onClose={handleCloseTooltip2}
                            open={openTooltip2}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <Typography>
                                Hier bitte angeben, welcher geschätzter Umsatz
                                nicht realisiert werden kann oder alternativ,
                                welche ungefähre Anzahl nicht abgesetzt werden
                                kann.
                              </Typography>
                            }
                          >
                            <IconButton
                              aria-label="delete"
                              disableRipple
                              style={{ padding: 8 }}
                            >
                              <InfoCircle onClick={handleOpenTooltip2} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                    </Box>
                    <Grid container spacing={1}>
                      <Grid item xs={10}>
                        <HouseField name="value">
                          {({ value, setValue, onBlur, errors }) => {
                            return (
                              <Grid container>
                                <Grid item xs={12}>
                                  <NumericFormat
                                    value={value}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    customInput={TextField}
                                    hiddenLabel
                                    fullWidth
                                    variant="filled"
                                    name="value"
                                    onValueChange={(values, sourceInfo) => {
                                      setValue(
                                        isNaN(values.floatValue) &&
                                          values.value != ""
                                          ? 0
                                          : values.floatValue
                                      );
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                      style: {
                                        borderRadius: 0,
                                        fontFamily: "Averta-Bold",
                                        color: "black",
                                      },
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            );
                          }}
                        </HouseField>
                      </Grid>
                      <Grid item xs={2}>
                        <HouseField name="type" initialValue={"euro"}>
                          {({ value, setValue }) => {
                            return (
                              <Select
                                fullWidth
                                disableUnderline
                                displayEmpty
                                className={classes.selectEmpty}
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                name="type"
                                InputLabelProps={{
                                  shrink: false,
                                  style: { display: "none" },
                                }}
                              >
                                <MenuItem value="euro">Euro</MenuItem>
                                <MenuItem value="pieces">Stück</MenuItem>
                              </Select>
                            );
                          }}
                        </HouseField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      disabled={!isValid}
                      variant="contained"
                      style={{
                        backgroundColor: "black",
                        opacity: !isValid ? 0.5 : 1,
                        borderRadius: 0,
                        boxShadow: "none",
                        color: "white",
                        fontFamily: "Averta-Bold",
                        textTransform: "none",
                      }}
                      onClick={submit}
                    >
                      Speichern
                    </Button>
                  </Grid>
                </Grid>
              )}
            </HouseForm>
          </Box>
          <Box position="absolute" bottom="3rem">
            <Typography variant="body1">*Pflichtfeld</Typography>
          </Box>
          <Box position="absolute" top="1.5rem" right="1.5rem">
            <CloseIconCircle onClick={toggleFeedbackDialog} fontSize="large" />
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        scroll="paper"
        PaperProps={{
          style: {
            background:
              "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 58%, var(--grey-20) 31%, var(--grey-20) 100%)",
          },
        }}
      >
        <Box className={classes.root}>
          <Grid container direction="column" style={{ overflow: "unset" }}>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                marginBottom="2rem"
              >
                <Typography
                  variant="h2"
                  className={classes.headline}
                  onClick={routeToIndex}
                >
                  ADM Playbook
                </Typography>
                <Box>
                  {!loggedIn && (
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.loginButton}
                      startIcon={<AccountCircleIcon />}
                      href={`${process.env.PREACT_APP_SSOURL}authorize?response_type=code&client_id=${process.env.PREACT_APP_SSOCLIENTID}&popup=1`}
                    >
                      Anmelden
                    </Button>
                  )}
                  {loggedIn && (
                    <>
                      {!!firstName && (
                        <span className={classes.loginInfo}>
                          Hallo {firstName}!
                        </span>
                      )}
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.loginButton}
                        startIcon={<ExitToAppIcon />}
                        onClick={logout}
                      >
                        Abmelden
                      </Button>                  
                    </>
                  )}
                  <IconButton
                    className={classes.closeButton}
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon fontSize="large" />
                  </IconButton>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 30,
                    mb: 3,
                  }}
                  onClick={handleTabChange}
                >
                  <Typography
                    variant="h4"
                    display="inline"
                    style={{
                      fontFamily: activeTab
                        ? "Averta-SemiBold"
                        : "Averta-Light",
                      cursor: "pointer",
                    }}
                  >
                    Kapitel
                  </Typography>
                  <Typography
                    variant="h4"
                    display="inline"
                    style={{
                      fontFamily: !activeTab
                        ? "Averta-SemiBold"
                        : "Averta-Light",
                      cursor: "pointer",
                    }}
                  >
                    Anwendungen
                  </Typography>
                </Box>

                <FormControlLabel
                  control={
                    <Switch
                      checked={toggleFilter}
                      onChange={handleToggleFilter}
                    />
                  }
                  label="Filter"
                />
              </Box>

              <Collapse in={toggleFilter}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  marginBottom={20 / 6}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-end",
                      gap: 20,
                    }}
                  >
                    {activeTab ? (
                      <>
                        <MuiToggleButton
                          name="onlyFavorite"
                          onChange={handleOrderBy}
                          selected={orderBy.onlyFavorite}
                        >
                          Favoriten zeigen
                        </MuiToggleButton>
                        <FormControl>
                          <MuiSelect
                            value={orderBy.company}
                            onChange={handleOrderBy}
                            inputProps={{
                              name: "company",
                            }}
                            defaultValue="*"
                          >
                            <MenuItem value="*">alle Marken</MenuItem>
                            <MenuItem value="abb">ABB</MenuItem>
                            <MenuItem value="bje">Busch-Jaeger</MenuItem>
                            <MenuItem value="suj">Striebel & John</MenuItem>
                          </MuiSelect>
                        </FormControl>
                        <FormControl>
                          <MuiSelect
                            value={orderBy.field}
                            onChange={handleOrderBy}
                            inputProps={{
                              name: "field",
                            }}
                          >
                            <MenuItem value="date">Datum</MenuItem>
                            <MenuItem value="alphabet">Kapitel</MenuItem>
                          </MuiSelect>
                        </FormControl>
                        <FormControl>
                          <MuiSelect
                            value={orderBy.sort}
                            onChange={handleOrderBy}
                            inputProps={{
                              name: "sort",
                            }}
                          >
                            <MenuItem value="asc">
                              {orderBy.field === "date"
                                ? "Älteste oben"
                                : "A - Z"}
                            </MenuItem>
                            <MenuItem value="desc">
                              {orderBy.field === "date"
                                ? "Neuste oben"
                                : "Z - A"}
                            </MenuItem>
                          </MuiSelect>
                        </FormControl>
                      </>
                    ) : (
                      <ToggleButtonGroup
                        value={appFilter}
                        onChange={handleAppFilterAdd}
                      >
                        {applicationToggleData}
                      </ToggleButtonGroup>
                    )}
                  </Box>
                  {activeTab ? (
                    <Fade in={toSave}>
                      <Box onClick={saveFilter}>
                        <MuiToggleButton name="saveFilter" onClick={saveFilter}>
                          {apperence ? (
                            <>
                              <SaveIcon
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 10,
                                }}
                              />
                              Filter speichern
                            </>
                          ) : (
                            <>Gespeichert!</>
                          )}
                        </MuiToggleButton>
                      </Box>
                    </Fade>
                  ) : (
                    <></>
                  )}
                </Box>
              </Collapse>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              overflow: "scroll",
              height: "calc(100% - 160px)",
              paddingBottom: "40px",
              paddingLeft: "10px",
              paddingRight: "10px",
              marginLeft: "-10px",
              marginRight: "-10px",
            }}
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "grid",
                  grid: "auto / calc((100% - 60px) / 4)  calc((100% - 60px) / 4) calc((100% - 60px) / 4) calc((100% - 60px) / 4) ",
                  gap: 20,
                  m: 0,
                }}
              >
                {activeTab ? chapterCards : applicationData}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </div>
  );
}
