import * as React from "react";
import {
  Box,
  Grid,
  makeStyles,
  Button,
  Dialog,
  Link,
  useMediaQuery,
} from "@material-ui/core";
import { useModal } from "mui-modal-provider";
import QDialog from "../QDialog";
import clsx from "clsx";
import imgElektrospicker from "../../assets/icons/elektrospicker.svg";
import imgPreisbeispiel from "../../assets/icons/preisbeispiel.svg";
import imgPreisliste from "../../assets/icons/preisliste.svg";
import imgLaptop from "../../assets/icons/laptop.svg";
import imgInfo from "../../assets/icons/infoIcon.svg";
import imgRegler from "../../assets/icons/regler.svg";
import imgMicrophone from "../../assets/icons/microphone.svg";
import imgYouTube from "../../assets/icons/youtube.svg";
import imgBestelldaten from "../../assets/icons/bestelldaten.svg";
import imgPDF from "../../assets/icons/pdf.svg";
import imgStromkompass from "../../assets/icons/stromkompass.svg";
import imgKonfiguratoren from "../../assets/icons/konfiguratoren.svg";
import imgKalender from "../../assets/icons/kalender.svg";
import imgSensorDistance from "../../assets/icons/sensor-distance.svg";
import imgThreeDMaximize from "../../assets/icons/3d-model-maximize.svg";
import imgGalery from "../../assets/icons/galery.svg";
import imgVoice from "../../assets/icons/voice.svg";
import imgGraph from "../../assets/icons/graph.svg";
import QGalery from "../QGalery";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { Swiper, SwiperSlide } from "swiper/react";
import { Zoom, Navigation, Pagination } from "swiper";
import QAppBar from "../QAppBar";
import { Document, Page } from "react-pdf";
import BetterPDFViewer from "../BetterPDFViewer";
import { downloadFile } from "../../utils/downloadFunction";

const useStyles = makeStyles(() => ({
  slideWrap: {
    position: "relative",
    height: "100dvh",
    overflow: "hidden",
  },
  slideContent: {
    height: "inherit",
  },
  slidePadding: {
    padding: "3.5rem 1.5rem 5rem 1.5rem",
    height: "calc(100dvh - (5rem + 3.5rem))",
    width: "calc(100dvw - 3rem)",
  },
  slidePaddingBottom: {
    padding: "3.5rem 1.5rem 3.5rem 1.5rem",
  },
  slidePaddingNoTop: {
    padding: "1.5rem 1.5rem 0 1.5rem",
  },
  actions: {
    width: "fit-content",
    alignItems: "center",
    position: "absolute",
    bottom: "28px",
    "& img": {
      width: "auto",
      maxHeight: "40px",
      maxWidth: "45px",
    },
    "&:hover": {
      cursor: "pointer",
    },
    paddingRight: "2.5rem",
    paddingLeft: "2.5rem",
  },
  childWrap: {
    paddingRight: "1rem",
    position: "relative",
    height: "inherit",
  },
  actionLink: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    fontSize: "12px",
    lineHeight: "1.5",
  },
  logo: {
    position: "absolute",
    bottom: "12px",
    right: "75px",
    zIndex: 200,
  },
}));

const BackgroundBox = ({ settings }) => (
  <Box
    left={settings.alignment === "left" ? "0px" : ""}
    right={settings.alignment === "right" ? "0px" : ""}
    top={settings.top}
    bottom={settings.bottom}
    position="absolute"
    width={settings.width}
    height={settings.height}
    style={{
      backgroundColor: settings.backgroundColor,
      backgroundSize: settings.backgroundSize,
      backgroundPosition: settings.backgroundPosition,
      backgroundImage:
        settings.src !== "" || settings.src !== null
          ? `url(${settings.src})`
          : null,
      backgroundRepeat: "no-repeat",
      zIndex: settings.zIndex,
      filter:
        settings.brightness !== undefined
          ? "brightness(" + settings.brightness + ")"
          : "brightness(100%)",
    }}
  />
);

export default function Slide({
  children,
  actions,
  background,
  noSlideClass,
  disablePadding,
  bottomPadding,
  defaultTopPadding,
  logo,
}) {
  const [newShowModal, setShowModal] = React.useState(-1);
  const [headline, setHeadline] = React.useState("");
  const classes = useStyles();
  const { showModal } = useModal();
  let isPortrait = false;
  if (background?.backgroundSize === "responsive") {
    isPortrait = useMediaQuery("(orientation : portrait)");
  }

  const handleShowModal = (id) => {
    if (actions[id]?.type === "betterGalery")
      setHeadline(actions[id].images[0].alt);
    setShowModal(id);
  };
  const handleHideModal = () => {
    setShowModal(false);
  };

  const dialog = (children) => {
    const d = showModal(QDialog, {
      children,
      onConfirm: () => {
        d.hide();
      },
      onCancel: () => {
        d.hide();
      },
    });
  };

  const slideChange = (slide, actionID) => {
    setHeadline(actions[actionID].images[slide.realIndex].alt);
  };

  const setHeadlineForInit = (text) => {
    setHeadline(text);
  };

  const actionItems = actions.map((action, i) => {
    let id = action.id || i;
    let item = null;
    let img = null;
    switch (action.img) {
      case "elektrospicker":
        img = imgElektrospicker;
        break;
      case "preisbeispiel":
        img = imgPreisbeispiel;
        break;
      case "preisliste":
        img = imgPreisliste;
        break;
      case "laptop":
        img = imgLaptop;
        break;
      case "info":
        img = imgInfo;
        break;
      case "regler":
        img = imgRegler;
        break;
      case "microphone":
        img = imgMicrophone;
        break;
      case "youtube":
        img = imgYouTube;
        break;
      case "bestelldaten":
        img = imgBestelldaten;
        break;
      case "pdf":
        img = imgPDF;
        break;
      case "stromkompass":
        img = imgStromkompass;
        break;
      case "konfiguratoren":
        img = imgKonfiguratoren;
        break;
      case "qvergleich":
        img = imgKalender;
        break;
      case "distance":
        img = imgSensorDistance;
        break;
      case "galery":
        img = imgGalery;
        break;
      case "audio":
        img = imgVoice;
        break;
      case "graph":
        img = imgGraph;
        break;
      case "3d-maximize":
        img = imgThreeDMaximize;
        break;
      default:
        img = action.imgSrc;
        break;
    }

    switch (action.type) {
      case "button":
        item =
          action.onClick || action.link.download ? (
            <Button
              variant="contained"
              onClick={
                action?.link?.download
                  ? () => downloadFile(action.link.href)
                  : action.onClick
              }
              style={
                action.buttonBackground === "white"
                  ? { backgroundColor: "#fff" }
                  : null
              }
            >
              {action.label}
            </Button>
          ) : (
            <Button
              href={action.link}
              variant="contained"
              target={action.target}
              style={
                action.buttonBackground === "white"
                  ? { backgroundColor: "#fff" }
                  : null
              }
            >
              {action.label && (
                <span style={{ paddingTop: "5px", fontSize: "12px" }}>
                  {action.label}
                </span>
              )}
            </Button>
          );
        break;
      case "dialogWithButton":
        item = (
          <Button
            variant="contained"
            style={
              action.buttonBackground === "white"
                ? { backgroundColor: "#fff" }
                : null
            }
            onClick={() => dialog(action.children)}
          >
            {action.label && (
              <span style={{ paddingTop: "5px", fontSize: "12px" }}>
                {action.label}
              </span>
            )}
          </Button>
        );
        break;
      case "dialog":
        const children = action.children;
        item = (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img loading="lazy" src={img} onClick={() => dialog(children)} />
            {action.label && (
              <span style={{ paddingTop: "5px", fontSize: "12px" }}>
                {action.label}
              </span>
            )}
          </div>
        );
        break;
      case "betterGalery":
        item = (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                loading="lazy"
                src={action.img ? img : imgGalery}
                onClick={() => handleShowModal(i)}
              />
              {action.label && (
                <span style={{ paddingTop: "5px", fontSize: "12px" }}>
                  {action.label}
                </span>
              )}
            </div>
            <QDialog
              onCancel={handleHideModal}
              open={i === newShowModal}
              alt={headline}
              children={
                <>
                  <Grid
                    container
                    style={{
                      width: "100vw",
                      height: "calc((100dvh - 64px)",
                    }}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={12} style={{ height: "100%" }}>
                      <Swiper
                        onSlideChange={(v) => slideChange(v, i)}
                        modules={[Navigation, Pagination]}
                        navigation
                        pagination={{ clickable: true }}
                        style={{ height: "calc(100vh - 64px)" }}
                      >
                        {action.images?.map((image) => {
                          return (
                            <SwiperSlide
                              zoom={false}
                              style={{ height: "calc(100vh - 64px)" }}
                            >
                              <TransformWrapper>
                                <TransformComponent>
                                  <Box
                                    width={"calc((100dvw - 65px) - 5em)"}
                                    height={"calc((100dvh - 65px) - 10em )"}
                                    padding="5em"
                                  >
                                    {image.children}
                                  </Box>
                                </TransformComponent>
                              </TransformWrapper>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </Grid>
                  </Grid>
                </>
              }
            />
          </>
        );

        break;
      case "galery":
        item = (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                loading="lazy"
                src={action.img ? img : imgGalery}
                onClick={() => handleShowModal(i)}
              />
              {action.label && (
                <span style={{ paddingTop: "5px", fontSize: "12px" }}>
                  {action.label}
                </span>
              )}
            </div>
            <QDialog
              onCancel={handleHideModal}
              open={i === newShowModal}
              children={
                <QGalery
                  images={action.images}
                  closeHandler={handleHideModal}
                />
              }
            />
          </>
        );

        break;
      case "pdf":
        item = (
          <>
            <BetterPDFViewer
              onCancel={handleHideModal}
              open={i === newShowModal}
              path={action.path}
            />
            {action.img ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                onClick={() => handleShowModal(i)}
              >
                <img loading="lazy" src={action.img ? img : imgGalery} />
                {action.label && (
                  <span style={{ paddingTop: "5px", fontSize: "12px" }}>
                    {action.label}
                  </span>
                )}
              </div>
            ) : (
              <Button
                variant="contained"
                style={
                  action.buttonBackground === "white"
                    ? { backgroundColor: "#fff" }
                    : null
                }
                onClick={() => handleShowModal(i)}
              >
                {action.label && (
                  <span style={{ paddingTop: "5px", fontSize: "12px" }}>
                    {action.label}
                  </span>
                )}
              </Button>
            )}
          </>
        );

        break;
      case "modal":
        item = (
          <div
            onClick={() => {
              const m = showModal(action.children, {
                mode: action.mode,
                year: action.year,
                onConfirm: () => {
                  m.hide();
                },
                onCancel: () => {
                  m.hide();
                },
              });
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img loading="lazy" src={img} style={{ marginRight: "1rem" }} />
            {action.label && (
              <span style={{ paddingTop: "5px", fontSize: "12px" }}>
                {action.label}
              </span>
            )}
          </div>
        );
        break;
      case "icon":
        item =
          action.link == "#" ? (
            <></>
          ) : (
            <Link
              href={action.link}
              target={action.target}
              className={classes.actionLink}
            >
              <img loading="lazy" src={img} />
              {action.label && (
                <span style={{ paddingTop: "5px", fontSize: "12px" }}>
                  {action.label}
                </span>
              )}
            </Link>
          );
        break;
      case "custom":
        item = action.custom;
        break;
    }

    return (
      <Grid item key={`${i}-action`}>
        {item}
      </Grid>
    );
  });

  const backgroundConfig = background
    ? {
        alignment: background.alignment ? background.alignment : "right",
        top: background.alignmentY === "top" ? "0px" : "",
        bottom: background.alignmentY === "bottom" ? "0px" : "",
        backgroundPosition: background.backgroundPosition
          ? background.backgroundPosition
          : "center",
        width: `calc(.5rem + ${background.width ? background.width : "50%"})`,
        maxWidth: "100%",
        height: background.height ? background.height : 3.575 / 4,
        backgroundSize:
          background.backgroundSize === "responsive"
            ? isPortrait
              ? "100% auto"
              : "auto 100%"
            : background.backgroundSize || "cover",
        src: background.src ? background.src : "null",
        zIndex: background.zIndex,
        backgroundColor: background.backgroundColor || "var(--grey-20)",
      }
    : null;

  return (
    <Box className={clsx(noSlideClass ? "" : "slide", classes.slideWrap)}>
      {background && <BackgroundBox settings={backgroundConfig} />}
      <Box
        className={clsx(
          classes.slideContent,
          disablePadding
            ? ""
            : bottomPadding
            ? classes.slidePaddingBottom
            : defaultTopPadding
            ? classes.slidePaddingNoTop
            : classes.slidePadding
        )}
      >
        <Box className={clsx(disablePadding ? "" : classes.childWrap)}>
          {children}
        </Box>
      </Box>
      {actionItems.length > 0 && (
        <Grid className={classes.actions} container spacing={2}>
          {actionItems}
        </Grid>
      )}
      {logo !== null && <Box className={classes.logo}>{logo}</Box>}
    </Box>
  );
}

Slide.defaultProps = {
  children: null,
  actions: [],
  noSlideClass: false,
  disablePadding: false,
  logo: null,
};
