import {
  Grid,
  makeStyles,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Box,
} from "@material-ui/core";
import { useModal } from "mui-modal-provider";
import root from "window-or-global";
import QDialog from "../QDialog";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  card: {
    height: "100%",
  },
  imgWrapper: {
    display: "flex",
    alignItems: "center",
    maxHeight: "calc(100vh / 3.5)",
    overflow: "hidden",
  },
}));

export default function QProductGroup({
  items,
  width,
  maxWidth,
  colWidthXS,
  colWidthSM,
  colWidthMD,
  colWidthLG,
  colWidthXL,
  marginLeft,
  fontSize,
  textBoxHight,
}) {
  const classes = useStyles();
  const { showModal } = useModal();

  const containerObject = {
    marginLeft,
    width,
    maxWidth,
  };

  const cardText = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    padding: "5px 10px",
    justifyContent: "center",
    height: textBoxHight,
  };

  const bold = {
    fontFamily: "Averta-Bold",
    fontSize: "clamp(14px, 1.2vw, 1.5rem)",
  };

  const centeredImage = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Grid container direction="row" style={containerObject} spacing={2}>
      {items.map((item) => {
        const action = (e) => {
          if (item.dialog) {
            const dialog = showModal(QDialog, {
              children: item.dialog ? (
                item.dialogOnlyImage ? (
                  <Box p={2} style={centeredImage}>
                    <Box
                      component="img"
                      sx={{
                        maxHeight: "90vh",
                        maxWidth: "60vw",
                      }}
                      alt="The house from the offer."
                      src={item.img}
                    />
                  </Box>
                ) : (
                  item.dialogContent
                )
              ) : (
                ""
              ),
              onConfirm: () => {
                dialog.hide();
              },
              onCancel: () => {
                dialog.hide();
              },
            });
          } else if (Object.prototype.hasOwnProperty.call(item, "callback")) {
            item.callback(item, e);
          } else {
            root.open(item.link, item.target, "noopener");
          }
        };

        let itemElement = item.placeholder ? (
          <Grid
            item
            xs={colWidthXS}
            sm={colWidthSM}
            md={colWidthMD}
            lg={colWidthLG}
            xl={colWidthXL}
          >
            <Card></Card>
          </Grid>
        ) : (
          <Grid
            item
            xs={colWidthXS}
            sm={colWidthSM}
            md={colWidthMD}
            lg={colWidthLG}
            xl={colWidthXL}
          >
            <Card
              className={clsx({
                [classes.card]: Object.prototype.hasOwnProperty.call(
                  item,
                  "title"
                ),
              })}
              style={{ height: item.cardHeight ? item.cardHeight : "100%" }}
              onClick={action}
            >
              {Object.prototype.hasOwnProperty.call(item, "title") && (
                <CardActionArea>
                  <Box className={classes.imgWrapper}>
                    <CardMedia
                      component="img"
                      image={item.img}
                      alt={item.title}
                    />
                  </Box>
                  {item.subTitle !== "" && (
                    <CardContent style={cardText}>
                      <Typography style={bold}>{item.title}</Typography>
                      <Typography style={{ fontSize }}>
                        {item.subTitle}
                      </Typography>
                    </CardContent>
                  )}
                </CardActionArea>
              )}
              {!Object.prototype.hasOwnProperty.call(item, "title") && (
                <Box className={classes.imgWrapper}>
                  <CardMedia
                    component="img"
                    image={item.img}
                    alt={item.title}
                  />
                </Box>
              )}
            </Card>
          </Grid>
        );
        return itemElement;
      })}
    </Grid>
  );
}

QProductGroup.defaultProps = {
  items: [],
  colWidthXS: 3,
  fontSize: "12px",
  textBoxHight: "fit-content",
};
